import React from "react";
import { CircularProgress, MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { aviaryBlue, cobalt, ghostWhite, LOADER_BG_COLOR } from "../theme";

interface OwnProps {
  open: boolean;
  isFormLoad?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    modal: (props: { isFormLoad: boolean | undefined }) => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 9999,
      inset: 0,
      backgroundColor: props.isFormLoad ? ghostWhite : LOADER_BG_COLOR,
      position: props.isFormLoad ? "absolute" : "fixed",
    }),
  })
);

export const spinnerTheme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        outline: "none !important",
      },
    },
  },
});

const useSpinnerStyles = makeStyles(theme =>
  createStyles({
    root: {
      position: "relative",
    },
    bottom: {
      color: cobalt,
    },
    top: {
      color: aviaryBlue,
      animationDuration: "550ms",
      position: "absolute",
      left: 0,
    },
    circle: {
      strokeLinecap: "round",
    },
  })
);

const Spinner = ({ open, isFormLoad }: OwnProps): JSX.Element => {
  const { modal } = useStyles({ isFormLoad });
  const classes = useSpinnerStyles();
  return (
    <>
      {open && (
        <div className={modal}>
          <MuiThemeProvider theme={spinnerTheme}>
            <div className={classes.root}>
              <CircularProgress variant="determinate" className={classes.bottom} size={40} thickness={4} value={100} />
              <CircularProgress
                variant="indeterminate"
                disableShrink
                className={classes.top}
                classes={{
                  circle: classes.circle,
                }}
                size={40}
                thickness={4}
              />
            </div>
          </MuiThemeProvider>
        </div>
      )}
    </>
  );
};

Spinner.defaultProps = {
  open: false,
};

export default Spinner;
