import React from "react";
import { deepBlue, iceBlue, oceanBlue, sapphireBlue, twilightBlue } from "../theme";

const JuakaliIcon = () => {
  return (
    <div>
      <svg width="46" height="46" viewBox="0 0 53 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_750_12995)">
          <path d="M35.7857 32.7803V46.5151L23.8916 39.6484L35.7857 32.7803Z" fill={deepBlue} />
          <path d="M35.7857 19.0459V32.7802L23.8916 25.914L35.7857 19.0459Z" fill={oceanBlue} />
          <path d="M23.8929 39.6484L23.8934 53.3828L12 46.516L23.8929 39.6484Z" fill={iceBlue} />
          <path d="M23.8939 39.6484L12 46.5172L12.0005 32.7822L23.8939 39.6484Z" fill={oceanBlue} />
          <path d="M23.8916 39.6484L35.7857 46.5152L23.8916 53.3816V39.6484Z" fill={twilightBlue} />
          <path d="M35.7857 32.7813L23.8916 25.915V39.6493L35.7857 32.7813Z" fill={sapphireBlue} />
          <path d="M35.7857 19.0468L23.8916 12.1807V25.915L35.7857 19.0468Z" fill={iceBlue} />
          <path d="M34.8833 15.8033L34.8825 6.7627L27.0527 11.2833L34.8833 15.8033Z" fill={oceanBlue} />
          <path d="M36.4328 2.76645L31.8506 0.617188L32.2795 5.66003L36.4328 2.76645Z" fill={sapphireBlue} />
          <path d="M40.7247 6.25795L40.188 2.58984L37.2793 4.88761L40.7247 6.25795Z" fill={deepBlue} />
        </g>
        <defs>
          <clipPath id="clip0_750_12995">
            <rect width="52.7246" height="52.7656" fill="white" transform="translate(0 0.617188)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default JuakaliIcon;
