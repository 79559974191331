import { createMuiTheme } from "@material-ui/core";
import { cobalt, lightBlackHover, rgbaLightGrey, snowWhite, white } from "../theme";
import { EMPTY } from "rxjs";
import { GRAPESJS_FORM } from "./constants";

export const teamTaskDetailStyles = (isMobileView: boolean): any =>
  createMuiTheme({
    overrides: {
      MuiIconButton: {
        root: {
          "&:hover": {
            backgroundColor: `${lightBlackHover} !important`,
          },
          padding: "12px !important",
          marginLeft: "6px",
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: "14px",
          fontWeight: 500,
        },
        tooltipPlacementBottom: { marginTop: "10px !important" },
      },
      MuiPaper: {
        elevation4: {
          padding: isMobileView ? "24px 16px 4px 16px" : "24px 24px 4px 24px",
        },
        rounded: {
          boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12) !important",
        },
        root: {
          backgroundColor: snowWhite,
        },
      },
      MuiTypography: {
        body1: {
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "22px",
          letterSpacing: "0.02em",
          textAlign: "left",
          color: "rgba(0, 0, 0, 0.54)",
        },
        h5: {
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "28px",
          letterSpacing: "0em",
          textAlign: "left",
        },
      },
    },
  });

export const assigneeSelectionStyles = {
  btnSmall: {
    minWidth: "auto",
    width: "34px",
    height: "34px",
    marginTop: "4px",
  },
  cancelButton: { marginLeft: "8px" },
  buttonWrapper: { paddingBottom: "10px" },
};

export const readOnlyTaskStyles = {
  paperElevation: { boxShadow: "2px 4px 12px 0px rgba(47, 66, 86, 0.10)", borderRadius: "4px", padding: 4 },
  typographyH5: { padding: "16px" },
  headerPadding: { padding: "16px" },
  headerTitleContainer: { display: "flex", alignItems: "center", paddingTop: "16px", paddingBottom: "24px" },
  redirectIcon: { color: "134A9F", FontSize: "18px", paddingLeft: "1rem", cursor: "pointer" },
};

export const contentStyles = (isMobileView: boolean, formType?: string): any => {
  return {
    headerIconContainer: { display: "flex", alignItems: "center", flexWrap: "wrap", paddingBottom: "20px", marginLeft: "-10px", cursor: "pointer" },
    backArrowIcon: { transform: "scale(1.5)", color: cobalt, fontSize: "14px", paddingRight: "8px", paddingLeft: "6px" },
    textStyle: { fontSize: "16px", color: cobalt, textTransform: "initial", paddingLeft: "4px", paddingRight: "10px", fontFamily: "Inter" },
    typographyProcessname: { paddingBottom: "20px", color: "#000000" },
    label: { fontWeight: "bold", fontSize: "12px", color: "rgba(0, 0, 0, 0.54)" },
    processNameStyle: { fontWeight: "400", fontSize: "20px", color: "#000000" },
    value: { color: "black", fontSize: "14px" },
    paddingTop: { paddingTop: "12px" },
    dueDateWrapper: { paddingRight: "16.5px" },
    border: { borderRight: isMobileView ? "" : "1px solid #BDBDBD", height: isMobileView ? "20px" : "60px" },
    assigneeWrapper: {
      paddingLeft: isMobileView ? EMPTY : "16.5px",
      flex: formType == GRAPESJS_FORM ? 0.2 : 0.3,
      paddingRight: isMobileView ? EMPTY : "16.5px",
    },
    flex: { display: "flex", paddingTop: "12px" },
    iconButton: { color: cobalt, marginTop: "-12px" },
    backIcon: { marginLeft: "-12px" },
    iconColor: { color: "#134A9F" },
    wrapper: { display: isMobileView ? "inline-block" : "flex" },
    backLink: { textDecoration: "none" },
    headerTitleContainer: { display: "flex", alignItems: "center", paddingBottom: "8px" },
    redirectIcon: { color: cobalt, cursor: "pointer" },
    reportHeaderContainer: { display: "flex", alignItems: "center", padding: "1rem 0" },
  };
};
