import { IconButton, Popover, Typography, useMediaQuery } from "@material-ui/core";
import { NotificationsNoneRounded } from "@material-ui/icons";
import { ListItem } from "@material/react-list";
import React, { Fragment } from "react";
import { TaskNotification } from "../../../types/taskQueryTypes";
import { cobalt } from "../../theme";
import { notificationStyles, popOverStyles } from "../styles";
import { WithTranslation, withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { checkAndUpdateTitle } from "./util";
import EmptyNotificationIcon from "../../common/EmptyNotificationIcon";
import { CommonToolTip } from "../../Dashboard/styles/commonStyles";

interface OwnProps extends WithTranslation {
  count: number;
  notification: TaskNotification;
  onClickAssignedTask: () => void;
  onClickCandidateTask: () => void;
  onClose: () => void;
  onOpen: () => void;
  open: boolean;
  t: i18n.TFunction;
}

function NotificationView(props: OwnProps) {
  const isItSmallMobile = useMediaQuery("(max-width: 600px)");
  const { count, notification, onClickAssignedTask, onClickCandidateTask, onClose, onOpen, open, t } = props;
  const {
    button,
    countBody,
    listBody,
    listButtonBody,
    listText,
    noNotificationBody,
    noNotificationText,
    notificationCount,
    notificationIcon,
    paper,
    popup,
    titleBody,
    titleContent,
    titleText,
    notificationBody,
    unreadText,
    notificationIconSize,
  } = notificationStyles(count, isItSmallMobile);
  const popOverStyle = popOverStyles();
  const totalNotification = count > 9 ? "9+" : count;
  checkAndUpdateTitle(totalNotification);
  const { assignedTasks, candidateTasks } = notification;

  const listContent = (info: string, onClick: () => void) => (
    <ListItem style={listBody} onClick={onClick} data-testid="listItem">
      <Typography variant="h6" style={listText} className="notificationInfo">
        {info}
      </Typography>
      <div style={listButtonBody}>
        <button onClick={onClick} style={button}>
          {t("common.new")}
        </button>
      </div>
    </ListItem>
  );

  const renderNotificationContent = (
    <Fragment>
      {assignedTasks.count ? listContent(t("notification.assignedTaskText", { count: assignedTasks.count }), onClickAssignedTask) : null}
      {candidateTasks.count ? listContent(t("notification.candidateTaskText", { count: candidateTasks.count }), onClickCandidateTask) : null}
    </Fragment>
  );

  const renderEmptyNotificationContent = (
    <div style={noNotificationBody} data-testid="emptyNotification">
      <EmptyNotificationIcon />
      <Typography variant="h6" style={noNotificationText}>
        {t("notification.noNotification")}
      </Typography>
    </div>
  );

  const renderNotificationPopUp = (
    <Popover
      id="open"
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      data-testid="popup"
      style={popup}
      classes={{
        root: popOverStyle.popoverRoot,
        paper: popOverStyle.paper,
      }}
    >
      <div style={paper}>
        <div style={titleBody}>
          <Typography variant="h5" style={titleText}>
            {t("notification.notifications")}
          </Typography>
          <div style={titleContent}>
            <Typography variant="h6" style={unreadText}>
              {t("notification.unreadNotification")} ({count})
            </Typography>
          </div>
        </div>
        <div style={notificationBody}>{count ? renderNotificationContent : renderEmptyNotificationContent}</div>
      </div>
    </Popover>
  );

  return (
    <Fragment>
      <CommonToolTip title={t("notification.tooltipTitle", { count }) as string}>
        <IconButton onClick={onOpen} style={notificationIcon} className="notificationButton">
          <div style={countBody}>
            <NotificationsNoneRounded htmlColor={cobalt} data-testid="notificationIcon" style={notificationIconSize} />
            {totalNotification ? <div style={notificationCount}>{totalNotification}</div> : null}
          </div>
        </IconButton>
      </CommonToolTip>
      {renderNotificationPopUp}
    </Fragment>
  );
}

export default withTranslation()(NotificationView);
