import React, { CSSProperties, useState } from "react";
import i18n from "i18next";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import { withStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, IconButton, useMediaQuery } from "@material-ui/core";
import { MuiThemeProvider, Typography } from "@material-ui/core";
import { defaultToolbarStyles, filterButtontheme, toolbarTheme } from "../styles/toolbarStyles";
import Chip from "@material-ui/core/Chip";
import { WithTranslation, withTranslation } from "react-i18next";
import { getFormattedDate, getTasksCount } from "../utils/toolbarHelper";
import { QuickFilter, TaskAssignment, TaskQueryFilterParams } from "../../../types/taskQueryTypes";
import { JuakaliUser, Paging } from "../../../redux/types";
import { getCurrentAssignee } from "../utils/dashboardHelper";
import { ASSIGNEE, DUEDATE, EMPTY, ERROR, REASSIGN_ERROR } from "../../../constants";
import { cobalt } from "../../theme";
import "../styles/styles.scss";
import SearchFilter from "../CustomToolbar/SearchFilter";
import CustomFilterDialog from "../CustomToolbar/CustomFilterDialog";
import { chipLabelStyles } from "../styles/dashboardStyles";
import { commonToolBarStyles, CommonToolTip } from "../styles/commonStyles";
import ErrorMessage from "../../common/ErrorMessage";
import { BOTTOM_PLACEMENT } from "../../tasks/constants";
import { JUAKALI_LANGUAGE, Languages } from "../../idm/IdmEnum";
import { useLanguageResponsiveQueries, useResponsiveQueries } from "../../../util/helper";

interface OwnProps {
  itemCount: string | number;
  status: string;
  setStatus: (assignment: TaskAssignment, status?: string) => void;
  assignment: TaskAssignment;
  defaultFilterParams?: TaskQueryFilterParams;
  filterNew?: boolean;
  applyFilter: (params?: TaskQueryFilterParams) => void;
  users?: JuakaliUser[];
  filterNewAssignedTask?: boolean;
  onNewFilterClick?: () => void;
  onTaskQuickFilter?(filterType: QuickFilter): void;
  activeQuickFilter?: QuickFilter;
  pagination: Paging;
  classes: any;
  drawerStatus: boolean | undefined;
}

interface OwnProps extends WithTranslation {
  t: i18n.TFunction;
}

const CommonToolbar: React.FC<OwnProps & WithTranslation> = ({
  t,
  applyFilter,
  defaultFilterParams,
  assignment,
  users,
  classes,
  pagination,
  activeQuickFilter,
  drawerStatus,
  status,
  setStatus,
  itemCount,
  onTaskQuickFilter,
}) => {
  const [isPopoverOpened, setIsPopoverOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);
  const isMobileView = useMediaQuery("(max-width: 600px)");
  const { isTabletView, drawerCloseMediaQuery } = useLanguageResponsiveQueries();
  const { drawerCloseWidth } = useResponsiveQueries();
  const isMobileLandscapeView = useMediaQuery("(max-height: 500px)");

  const { dueDate, taskName, assignee } = defaultFilterParams || { dueDate: null, taskName: "", assignee: "" };
  const isFilterApplied = dueDate || taskName || assignee;
  const commonToolBarStyle = commonToolBarStyles(isMobileView, isPopoverOpened, isTabletView, drawerStatus, drawerCloseMediaQuery);

  const handleClick = (event: any) => {
    setIsPopoverOpened(prev => !prev);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (params: { isPopoverOpened: boolean; anchorEl?: HTMLAnchorElement | null }) => {
    setIsPopoverOpened(params.isPopoverOpened);
    setAnchorEl(params.anchorEl || null);
  };

  const removeFilters = (type: string) => {
    switch (type) {
      case DUEDATE:
        applyFilter({ dueDate: null });
        break;
      case ASSIGNEE:
        applyFilter({ assignee: "" });
        break;
      default:
        break;
    }
  };

  const applyQuickFilter = (filterType: QuickFilter) => {
    if (onTaskQuickFilter) {
      onTaskQuickFilter(filterType);
    }
  };

  return (
    <React.Fragment>
      <MuiThemeProvider theme={toolbarTheme}>
        <Grid
          className="filters"
          container
          direction={drawerStatus ? (isMobileView || isTabletView ? "column" : "row") : drawerCloseWidth ? "column" : "row"}
          justify="space-between"
          alignItems={isTabletView ? "flex-start" : "center"}
        >
          {/* Quick Filters button */}
          <Grid
            xs={12}
            sm={12}
            md={(isMobileView || isTabletView) && drawerStatus ? 12 : 7}
            lg={7}
            item
            className={`${classes.quickFilterRoot} quickFilters`}
            style={commonToolBarStyle.quickFilters as CSSProperties}
          >
            {[QuickFilter.ALL, QuickFilter.NEW, QuickFilter.TODAY, QuickFilter.WEEK, QuickFilter.OVERDUE].map(filterType =>
              assignment === TaskAssignment.CANDIDATE && filterType === QuickFilter.NEW ? null : (
                <Grid item key={filterType}>
                  <Button
                    variant="outlined"
                    className={classes[filterType] + (activeQuickFilter === filterType ? " active" : "")}
                    onClick={() => applyQuickFilter(filterType)}
                  >
                    {t(`dashboard.${filterType}`)} ({getTasksCount(filterType, pagination)})
                  </Button>
                </Grid>
              )
            )}
          </Grid>

          {/* Search filter */}
          <Grid
            item
            xs={12}
            sm={12}
            md={(isMobileView || isTabletView) && drawerStatus ? 12 : 4}
            lg={4}
            style={commonToolBarStyle.filters as CSSProperties}
          >
            <SearchFilter
              assignment={assignment}
              applyFilter={applyFilter}
              defaultFilter={defaultFilterParams && defaultFilterParams.taskName}
              isMobileView={isMobileView}
            />
            <MuiThemeProvider theme={filterButtontheme}>
              <CommonToolTip title={t("dashboard.filters") as string} placement={BOTTOM_PLACEMENT}>
                <IconButton aria-label="filter" onClick={handleClick} style={commonToolBarStyle.menuIcon} data-testid="Filter">
                  <FilterListRoundedIcon htmlColor={cobalt} />
                </IconButton>
              </CommonToolTip>
            </MuiThemeProvider>
            <CustomFilterDialog
              defaultFilterParams={defaultFilterParams}
              anchorEl={anchorEl}
              applyFilter={applyFilter}
              isPopoverOpened={isPopoverOpened}
              handleClosePopover={handlePopoverClose}
              users={users}
              assignment={assignment}
              isMobileView={isMobileView}
              isMobileLandscapeView={isMobileLandscapeView}
            />
          </Grid>
        </Grid>

        <Box mt={2} display="flex" gridGap={8} alignItems="center" marginTop={0} className="quickFilters">
          {dueDate && (
            <Box mt={2} display="flex" alignItems="center">
              <Typography variant={"body2"} style={chipLabelStyles.label}>{`${t(`common.dueBefore`)}:`}</Typography>
              <Chip clickable label={getFormattedDate(dueDate, "-")} onDelete={() => removeFilters(DUEDATE)} onClick={() => removeFilters(DUEDATE)} />
            </Box>
          )}
          {assignee && assignee !== EMPTY && (
            <Box mt={2} display="flex" alignItems="center">
              <Typography variant={"body2"} style={chipLabelStyles.label}>{`${t(`dashboard.assignee`)}:`}</Typography>
              <Chip
                clickable
                label={getCurrentAssignee(assignee, users || [], false, true) as JuakaliUser | undefined}
                onDelete={() => removeFilters(ASSIGNEE)}
                onClick={() => removeFilters(ASSIGNEE)}
              />
            </Box>
          )}
          <Box mt={2} display="flex" alignItems="baseline">
            {isFilterApplied && (
              <Typography style={commonToolBarStyle.helperText} key={"result"}>{`${itemCount} ${
                itemCount == 1 ? t("dashboard.result") : t("dashboard.results")
              }`}</Typography>
            )}
          </Box>
        </Box>

        {(status === REASSIGN_ERROR || status === ERROR) && (
          <Box mt={2} display="flex" flexWrap="wrap" alignItems="center" marginTop={0}>
            <ErrorMessage
              errorMessage={
                status === ERROR || status === REASSIGN_ERROR
                  ? status === ERROR
                    ? t(`dashboard.technicalError`)
                    : t(`dashboard.reassignError`)
                  : EMPTY
              }
              handleClear={() => setStatus(assignment)}
            />
          </Box>
        )}
      </MuiThemeProvider>
    </React.Fragment>
  );
};

export default withStyles(defaultToolbarStyles, { name: "CommonToolbar" })(withTranslation()(CommonToolbar));
