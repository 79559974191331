import React from "react";
import { deepBlue, deepIndigo, iceBlue, oceanBlue, sapphireBlue, twilightBlue, white } from "../theme";

const JuakaliVectorIcon = () => {
  return (
    <svg width="140" height="50" viewBox="0 0 160 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_24_1343)">
        <path
          d="M37.2692 41.9364C35.7122 41.9364 34.4143 41.6249 33.3784 40.9995C32.3388 40.3759 31.4852 39.6021 30.8115 38.6797L33.1457 36.2876C33.7409 37.037 34.3509 37.6189 34.9782 38.031C35.6014 38.4444 36.3451 38.6501 37.2109 38.6501C38.1717 38.6501 38.9439 38.3292 39.5297 37.6856C40.1168 37.0413 40.4093 36.0478 40.4093 34.7014V21.4736H44.041V34.7889C44.041 35.9994 43.8793 37.047 43.5502 37.9316C43.2243 38.8131 42.7618 39.553 42.1685 40.1489C41.5726 40.7453 40.8605 41.192 40.0337 41.4896C39.2084 41.7878 38.2867 41.9364 37.2692 41.9364Z"
          fill={deepIndigo}
        />
        <path
          d="M66.5639 21.5128V33.0632C66.5639 39.0266 62.9084 42.08 57.8373 42.08C52.9976 42.08 48.8797 39.1417 48.8797 33.0632V21.5128H52.6534V33.0632C52.6534 36.6923 54.7266 38.6226 57.8674 38.6226C61.0052 38.6226 62.7933 36.5206 62.7933 33.0632V21.5128H66.5639Z"
          fill={deepIndigo}
        />
        <path
          d="M80.4932 25.7178L76.749 34.3022H84.2375L80.4932 25.7178ZM85.7643 37.8445H75.2213L73.4936 41.6472H69.375L78.4188 21.4834H82.5657L91.6136 41.6472H87.4638L85.7643 37.8445Z"
          fill={deepIndigo}
        />
        <path
          d="M110.377 21.5985L101.417 31.2488L111.125 41.4182V41.6472H106.429L97.991 32.718V41.6472H94.1885V21.4834H97.991V30.01L105.653 21.4834H110.377V21.5985Z"
          fill={deepIndigo}
        />
        <path
          d="M123.266 25.7178L119.523 34.3022H127.011L123.266 25.7178ZM128.538 37.8445H117.995L116.267 41.6472H112.147L121.193 21.4834H125.338L134.386 41.6472H130.236L128.538 37.8445Z"
          fill={deepIndigo}
        />
        <path d="M141.365 21.4834V38.1327H151.735V41.6472H137.563V21.4834H141.365Z" fill={deepIndigo} />
        <path d="M155.605 41.6472V21.4834H159.379V41.6472H155.605Z" fill={deepIndigo} />
        <path d="M23.7857 32.1631V45.8979L11.8916 39.0312L23.7857 32.1631Z" fill={deepBlue} />
        <path d="M23.7857 18.4287V32.163L11.8916 25.2968L23.7857 18.4287Z" fill={oceanBlue} />
        <path d="M11.8929 39.0312L11.8934 52.7656L0 45.8988L11.8929 39.0312Z" fill={iceBlue} />
        <path d="M11.8939 39.0313L0 45.9L0.000503066 32.165L11.8939 39.0313Z" fill={oceanBlue} />
        <path d="M11.8916 39.0312L23.7857 45.898L11.8916 52.7644V39.0312Z" fill={twilightBlue} />
        <path d="M23.7857 32.1641L11.8916 25.2979V39.0322L23.7857 32.1641Z" fill={sapphireBlue} />
        <path d="M23.7857 18.4296L11.8916 11.5635V25.2978L23.7857 18.4296Z" fill={iceBlue} />
        <path d="M22.8833 15.1861L22.8825 6.14551L15.0527 10.6661L22.8833 15.1861Z" fill={oceanBlue} />
        <path d="M24.4326 2.14922L19.8504 -4.57764e-05L20.2793 5.0428L24.4326 2.14922Z" fill={sapphireBlue} />
        <path d="M28.7247 5.64076L28.188 1.97266L25.2793 4.27042L28.7247 5.64076Z" fill={deepBlue} />
      </g>
      <defs>
        <clipPath id="clip0_24_1343">
          <rect width="160" height="52.766" fill={white} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default JuakaliVectorIcon;
