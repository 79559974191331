import React, { useCallback, useEffect, useState } from "react";
import { MuiThemeProvider } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { EMPTY } from "../../../constants";
import { dashboardTableTheme } from "../../common/theme";
import { debounce } from "lodash";

export default function DataTable({ key, columns, data, options, innerRef }: any) {
  const HEIGHT = "500px";
  const RESIZE = "resize";
  const [tableHeight, setTableHeight] = useState(HEIGHT);

  const calculateTableHeight = useCallback(
    debounce(() => {
      const windowHeight = window.innerHeight;
      if (windowHeight <= 800) {
        setTableHeight(HEIGHT);
        return;
      }
      const tabElement = document.querySelector(".tabs");
      const filterElement = document.querySelector(".filters");
      const tabsHeight = tabElement ? tabElement.getBoundingClientRect().height : 0;
      const filtersHeight = filterElement ? filterElement.getBoundingClientRect().height : 0;
      const availableHeight = windowHeight - (tabsHeight + filtersHeight + 250);
      setTableHeight(`${availableHeight}px`);
    }, 200),
    []
  );

  useEffect(() => {
    calculateTableHeight();
    window.addEventListener(RESIZE, calculateTableHeight);
    return () => {
      window.removeEventListener(RESIZE, calculateTableHeight);
      calculateTableHeight.cancel();
    };
  }, [calculateTableHeight]);
  const customOptions = {
    ...options,
    fixedHeader: true,
    tableBodyMaxHeight: tableHeight,
  };
  return (
    <div className="dashboardDataTableContainer">
      <MuiThemeProvider theme={dashboardTableTheme}>
        <MUIDataTable title={EMPTY} key={key} columns={columns} data={data} options={customOptions} innerRef={innerRef} />
      </MuiThemeProvider>
    </div>
  );
}
