import { createMuiTheme } from "@material-ui/core";
import {
  aviaryBlue,
  black,
  cobalt,
  ghostWhite,
  lavenderBlue,
  midNightBlue,
  paleGrey,
  powderBlue,
  skyGray,
  snowWhite,
  TOOLTIP_BACKGROUND_COLOR,
  white,
} from "../theme";
import { EMPTY } from "../../constants";
import { INTER_FONT } from "../tasks/constants";

export const muiTableTheme = (createMuiTheme as any)({
  overrides: {
    MuiGridList: {
      root: {
        maxHeight: 300,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
        fontFamily: INTER_FONT,
        fontWeight: 500,
        backgroundColor: TOOLTIP_BACKGROUND_COLOR,
      },
    },
    MUIDataTableToolbar: {
      actions: {
        display: "flex",
        flexDirection: "row-reverse",
        gap: 24,
        flex: 1,
        paddingTop: "8px",
        paddingBottom: "8px",
        "& > span > button": {
          color: cobalt,
          borderColor: aviaryBlue,
          borderStyle: "solid",
          borderWidth: "1px",
          borderRadius: 4,
          padding: "8px !important",
        },
      },
      titleRoot: {
        display: "flex",
        wordWrap: "break-word",
        paddingTop: "8px",
        paddingBottom: "8px",
      },
      titleText: {
        color: midNightBlue,
        fontFamily: INTER_FONT,
        fontSize: "20px !important",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "28px",
        letterSpacing: "0.2px",
        wordBreak: "break-all",
        textAlign: "left !important",
        display: "-webkit-box",
        overflow: "hidden",
        "-webkit-line-clamp": "3",
        "-webkit-box-orient": "vertical",
      },
      filterCloseIcon: {
        color: black,
        opacity: 0.8,
        top: "16px !important",
      },
      icon: {
        "&:hover": {
          color: cobalt,
        },
      },
      root: {
        display: "flex !important",
        justifyContent: "flex-start",
        overflow: "hidden",
        flexWrap: "wrap",
        alignItems: "flex-start",
      },
    },
    MuiIconButton: {
      root: {
        width: "40px !important",
        height: "40px !important",
        "&:hover": {
          backgroundColor: lavenderBlue,
          borderColor: cobalt,
        },
      },
    },
    MuiTableCell: {
      root: {
        fontFamily: INTER_FONT,
        backgroundColor: white,
      },
      head: {
        color: midNightBlue,
        fontWeight: 600,
        fontSize: 16,
        lineHeight: "24px",
      },
      body: {
        whitespace: "nowrap",
      },
    },
    MUIDataTableHeadCell: {
      toolButton: { backgroundColor: paleGrey },
      fixedHeader: {
        backgroundColor: paleGrey,
        border: "none",
      },
      sortAction: {
        alignItems: "center",
      },
    },
    MuiTablePagination: {
      root: {
        backgroundColor: ghostWhite,
        border: "none",
        "&:last-child": {
          padding: "0px !important",
        },
        "-ms-overflow-style": "none",
        "scrollbar-width": "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      },
      caption: {
        fontFamily: `${INTER_FONT} !important`,
      },
      select: {
        fontFamily: `${INTER_FONT} !important`,
      },
    },
    MUIDataTable: {
      responsiveBase: {
        marginTop: 24,
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        "scrollbar-width": "thin",
        "@media (max-width: 600px)": {
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        },
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: "0 !important",
        paddingRight: "0 !important",
      },
      regular: {
        minHeight: "auto !important",
      },
    },
    MuiInputLabel: {
      formControl: {
        transform: "translate(0,12px) scale(1)",
      },
    },
    MuiChip: {
      root: {
        margin: 4,
        backgroundColor: powderBlue,
        fontFamily: INTER_FONT,
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "24px",
        letterSpacing: "1%",
      },
      deleteIcon: {
        color: skyGray,
        "&:hover": {
          color: cobalt,
          backgroundColor: paleGrey,
        },
      },
      clickable: {
        "&:hover": {
          backgroundColor: powderBlue,
        },
      },
    },
    MUIDataTableFilterList: {
      root: {
        margin: 0,
        "@media (max-width: 600px)": {
          overflowX: "scroll",
          whitespace: "nowrap",
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
        },
      },
    },
  },
});

export const dashboardTableTheme = (createMuiTheme as any)({
  overrides: {
    MuiTableCell: {
      root: {
        BorderBottom: "0px solid transparent",
        fontFamily: INTER_FONT,
      },
      head: {
        color: midNightBlue,
        fontWeight: 600,
        fontSize: 16,
        lineHeight: "24px",
        position: "sticky",
        top: "0px",
        zIndex: 2,
        "&:first-child": {
          border: "none",
          backgroundColor: paleGrey,
          paddingLeft: "36px",
          paddingTop: "7px",
          paddingBottom: "7px",
        },
        "&:last-child": {
          paddingRight: "30px",
          width: 50,
          paddingLeft: 12,
        },
      },
      body: {
        "&:first-child": {
          paddingLeft: "30px",
        },
        "&:last-child": {
          paddingRight: "30px",
          textAlign: "left",
          paddingLeft: 0,
        },
        paddingTop: "14px",
        paddingBottom: "14px",
      },
    },
    MUIDataTableHeadCell: {
      toolButton: { backgroundColor: paleGrey },
      fixedHeader: {
        backgroundColor: paleGrey,
        border: "none",
      },
      sortAction: {
        alignItems: "center",
      },
    },
    MuiPaper: {
      root: {
        marginTop: 16,
        backgroundColor: ghostWhite,
      },
      elevation4: {
        boxShadow: "none !important",
      },
    },
    MuiTablePagination: {
      root: {
        backgroundColor: ghostWhite,
        border: "none",
        padding: 0,
        "&:last-child": {
          padding: "0px !important",
        },
        "-ms-overflow-style": "none",
        "scrollbar-width": "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      },
      toolbar: {
        paddingRight: 0,
        minHeight: EMPTY,
      },
      caption: {
        fontFamily: `${INTER_FONT} !important`,
      },
      select: {
        fontFamily: `${INTER_FONT} !important`,
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MUIDataTable: {
      responsiveBase: {
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        "scrollbar-width": "thin",
        "@media (max-width: 600px)": {
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        },
      },
    },
    MuiTableBody: {
      backgroundColor: `${snowWhite} !important`,
    },
    MUIDataTableBody: {
      emptyTitle: {
        fontSize: "14px !important",
        fontFamily: INTER_FONT,
      },
    },
  },
});
const getStyles = (status: string | number) => {
  return status
    ? {
        zIndex: 1000,
        fontSize: "16px !important",
        borderRadius: "4px 0px 0px 4px",
        width: "280px",
        position: "fixed",
        right: "0",
        margin: "25px 15px 0 0 !important",
        textAlign: "left",
      }
    : {
        fontSize: "12px !important",
        alignItems: "center",
        margin: "0px 16px 16px 0px !important",
        borderRadius: "4px",
        maxWidth: "400px",
        whiteSpace: "pre-line",
        textAlign: "center",
      };
};

export const messageTheme = (status: string | number): any =>
  (createMuiTheme as any)({
    overrides: {
      MuiTypography: {
        body2: {
          border: `2px solid ${status ? "rgba(255, 152, 0, 1)" : "rgba(213, 0, 0, 0.8)"} !important`,
          backgroundColor: "#FFF4F4",
          color: status ? "rgba(255, 152, 0, 1)" : "rgba(213, 0, 0, 0.8)",
          padding: "16px",
          fontStyle: "normal",
          lineHeight: "24px !important",
          letterSpacing: "0.15",
          fontWeight: "600 !important",
          display: "flex",
          justifyContent: "space-between",
          ...getStyles(status),
        },
      },
    },
  });

export const documentLoader = {
  flexDirection: "column" as any,
  position: "fixed" as any,
  background: "rgba(0, 0, 0, 0.3)",
};

export const typographyStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  display: "-webkit-box",
};
