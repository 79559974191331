import { CircularProgress, Grid, IconButton, MuiThemeProvider, Typography } from "@material-ui/core";
import MUIDataTable, { MUIDataTableColumn, TableFilterList } from "mui-datatables";
import React, { useState, Fragment, useCallback, useEffect } from "react";
import { CONTAINS, EMPTY, EQUAL, EQUALS, LIKE, REPORT, TableKeys } from "../../constants";
import { DataTableProps, DynamicJson, FilterParam } from "../../datastore/dataStoreTypes";
import { SelectableRows } from "../../redux/types";
import { setRowsPerPageSize } from "../../util/helper";
import { muiTableTheme } from "../common/theme";
import CustomToolBar from "./CustomToolBar";
import { getColumnsArray, getFilterParams, handleExportClick } from "./objectDataReportHelper";
import { customToolBarStyle, useStyles } from "./objectDataStyles";
import { loaderWrapper, loaderStyle } from "../Dashboard/styles/tableStyles";
import { showNotification } from "../../util/notification";
import { CUT, GRAPES_JS_FORM } from "../TeamTask/constants";
import { ReportTableState } from "./reportTypes";
import CustomFilterDialogFooter from "./CustomFilterDialogFooter";
import ReportFilterDialogBloc, { defaultReportFilterDialogState, updateReportFilterDialogBlocState } from "../../rxjs/reportFilterDialogBloc";
import ObjectAnalysisReport from "./ObjectAnalysisReport";
import ReportsBloc, { defaultReportsState, ReportsState } from "../../rxjs/reportsBloc";
import { useBloc } from "../../rxjs/blocBuilder";
import ExcelExportIcon from "../common/ExcelExportIcon";
import { CommonToolTip } from "../Dashboard/styles/commonStyles";
import { exportIconTheme, reportStyles } from "./reportStyles";
import { debounce } from "lodash";

function ObjectDataTable(props: DataTableProps) {
  const {
    filterBody,
    formType,
    handleFilterData,
    isMobileView,
    listState,
    objectDefinition,
    remoteObjectData,
    rerenderTable,
    setListState,
    t,
    handleSelectedRow,
  } = props;
  const { clonedFormData: formData, filterList, isExactSearch, objectDataArray, sortOrder } = listState;
  const [state, setState] = useState<ReportTableState>({ isExcelDownloading: false, showReportDownloadError: false });
  const reportsBloc = ReportsBloc.getInstance();
  const formsState = useBloc<ReportsState>(defaultReportsState, reportsBloc.getSubject());
  const { currentTab } = formsState.data || defaultReportsState;
  const { isExcelDownloading, showReportDownloadError } = state;
  const { errorMessage, messageContainer } = useStyles();
  const { key, label, version, externalAnalysisUrl } = objectDefinition;
  const { count, page, pageSize } = remoteObjectData;
  const sortParams = { param: sortOrder.name, order: sortOrder.direction.toUpperCase() };
  const searchType = isExactSearch ? EQUALS : CONTAINS;
  const [tableHeight, setTableHeight] = useState("600px");
  const HEIGHT = "600px";
  const RESIZE = "resize";
  const applyFilter = (filterList: string[][]): void => {
    const { isExactSearch } = ReportFilterDialogBloc.getInstance().getSubject().value;
    const filterParams = getFilterParams(columnsArray, filterList, isExactSearch ? EQUALS : CONTAINS);
    setListState(state => ({ ...state, filterList, isExactSearch, filterParams }));
    handleFilterData({
      key: key,
      filterParams: filterParams,
      pageSize,
      sortParams,
    });
  };

  const handleClick = (): void => {
    setState(state => ({ ...state, showReportDownloadError: false }));
    const filterParams = getFilterParams(columnsArray, filterList, searchType) as FilterParam[];
    const filterParamObject = filterParams.reduce((acc: DynamicJson, item: DynamicJson): DynamicJson => {
      acc[item.key] = item.value;
      return acc;
    }, {});

    handleExportClick({
      ...props,
      filterParams: filterParamObject,
      searchType: searchType == EQUALS ? EQUAL : LIKE,
      objectDefinitionKey: key,
      objectDefinitionLabel: label,
      version: version,
      setState: setState,
      label: label,
    });
  };

  let columnsArray = getColumnsArray(objectDefinition, isMobileView, filterList, sortOrder, applyFilter);
  const { tableTitleStyle } = reportStyles();

  const excelIcon = (
    <MuiThemeProvider theme={exportIconTheme}>
      <CommonToolTip title={t("reports.exportExcel") as string} style={customToolBarStyle.button}>
        <IconButton onClick={handleClick} data-testid="excelExportIcon" style={customToolBarStyle.iconButton}>
          <ExcelExportIcon />
        </IconButton>
      </CommonToolTip>
    </MuiThemeProvider>
  );

  const calculateTableHeight = useCallback(
    debounce(() => {
      const windowHeight = window.innerHeight;
      if (windowHeight <= 600) {
        setTableHeight(HEIGHT);
        return;
      }
      const availableHeight = windowHeight - 250;
      setTableHeight(`${availableHeight}px`);
    }, 200),
    []
  );
  useEffect(() => {
    calculateTableHeight();
    window.addEventListener(RESIZE, calculateTableHeight);
    return () => {
      window.removeEventListener(RESIZE, calculateTableHeight);
      calculateTableHeight.cancel();
    };
  }, [calculateTableHeight]);

  return (
    <div style={loaderWrapper} className="objectDataTableContainer">
      {(rerenderTable || isExcelDownloading) && (
        <div style={loaderStyle}>
          <CircularProgress size={24} />
        </div>
      )}
      {currentTab == REPORT ? (
        <div style={customToolBarStyle.toolBarContainer}>
          <Grid container justify="space-between" alignItems="center" style={customToolBarStyle.containerStyle} className="recordsTabHeader">
            <Grid item style={customToolBarStyle.headerTextStyle}>
              <Typography variant="h6" style={tableTitleStyle} data-testid="tableTitle">
                {label}
              </Typography>
            </Grid>
            <Grid item style={customToolBarStyle.exportIconStyle} className="excelIcon">
              {excelIcon}
            </Grid>
          </Grid>
          <CustomToolBar
            objectDefinitionKey={key}
            objectDefinition={objectDefinition}
            objectDefinitionLabel={label}
            version={version}
            filterParams={getFilterParams(columnsArray, filterList, searchType)}
            searchType={searchType}
            setState={setState}
            label={label}
          />
          <ObjectAnalysisReport externalAnalysisUrl={externalAnalysisUrl} />
        </div>
      ) : (
        <MuiThemeProvider theme={muiTableTheme}>
          <MUIDataTable
            key={rerenderTable ? "loading" : "loaded"}
            title={label}
            columns={columnsArray}
            data={objectDataArray || []}
            options={{
              fixedHeader: true,
              tableBodyMaxHeight: tableHeight,
              download: false,
              search: false,
              filter: true,
              sort: true,
              filterType: "custom",
              onFilterChange: (_: string | MUIDataTableColumn | null, filterList, type) => {
                if (type === "chip") {
                  setListState(state => ({ ...state, filterList }));
                  handleFilterData({
                    ...filterBody,
                    page: 0,
                    pageSize,
                    sortParams,
                    filterParams: getFilterParams(columnsArray, filterList, searchType),
                  });
                  return;
                }
                if (type === "reset") {
                  updateReportFilterDialogBlocState(defaultReportFilterDialogState);
                }
              },
              customFilterDialogFooter: filterList => (
                <CustomFilterDialogFooter applyFilter={applyFilter} filterList={filterList} isExactSearch={isExactSearch} />
              ),
              viewColumns: false,
              responsive: "standard",
              print: false,
              selectableRows: "none" as SelectableRows,
              pagination: true,
              serverSide: true,
              count: count,
              page: page,
              rowsPerPage: pageSize,
              rowsPerPageOptions: [10, 25, 50],
              onChangeRowsPerPage: pageSize => {
                setRowsPerPageSize(TableKeys.OBJECT_DATA_LISTS, pageSize);
              },
              customToolbar: () => excelIcon,
              textLabels: {
                body: {
                  noMatch: EMPTY,
                  toolTip: t("reports.sort"),
                } as any,
                pagination: {
                  rowsPerPage: t("reports.rowsPerPage"),
                  displayRows: t("reports.displayRows"),
                },
                filter: {
                  all: t("reports.all"),
                  title: t("reports.title"),
                  reset: t("reports.reset"),
                },
                toolbar: {
                  filterTable: t("reports.filterTable"),
                },
              } as any,
              onRowClick: (rowData: string[]) => {
                if (formType === GRAPES_JS_FORM) {
                  formData && formData.modelJson ? handleSelectedRow(rowData) : showNotification("error", t("datastore.reportFormError"));
                } else if (formType === CUT) {
                  const modelData = formData && formData.grapesForms.map((form: any) => form.model);
                  formData && modelData ? handleSelectedRow(rowData) : showNotification("error", t("datastore.reportFormError"));
                }
              },
              onTableChange: (action, tableState: any) => {
                action !== "propsUpdate" && showReportDownloadError && setState({ ...state, showReportDownloadError: false });
                switch (action) {
                  case "changePage":
                    handleFilterData({
                      ...filterBody,
                      page: tableState.page,
                      pageSize: tableState.rowsPerPage,
                      filterParams: getFilterParams(columnsArray, tableState.filterList, searchType),
                      sortParams,
                    });
                    break;
                  case "changeRowsPerPage": {
                    handleFilterData({
                      ...filterBody,
                      page: 0,
                      pageSize: tableState.rowsPerPage,
                      filterParams: getFilterParams(columnsArray, tableState.filterList, searchType),
                      sortParams,
                    });
                    break;
                  }
                  case "sort": {
                    if (tableState && tableState.columns && tableState.activeColumn) {
                      var column = tableState.columns[tableState.activeColumn];
                      if (column) {
                        const { name, direction } = sortOrder;
                        const sortDirection = name ? (direction === "desc" ? "asc" : "desc") : "desc";
                        handleFilterData({
                          ...filterBody,
                          page: 0,
                          pageSize: tableState.rowsPerPage,
                          sortParams: { param: column.name, order: sortDirection.toUpperCase() },
                          filterParams: getFilterParams(columnsArray, tableState.filterList, searchType),
                        });
                        setListState(state => ({ ...state, sortOrder: { name: column.name, direction: sortDirection } }));
                      }
                    }
                    break;
                  }
                }
              },
              sortOrder: { ...sortOrder, direction: sortOrder.direction === "none" ? "asc" : sortOrder.direction },
            }}
            components={{
              TableFilterList: props => (
                <Fragment>
                  {objectDefinition.externalAnalysisUrl && (
                    <CustomToolBar
                      objectDefinitionKey={key}
                      objectDefinition={objectDefinition}
                      objectDefinitionLabel={label}
                      version={version}
                      filterParams={getFilterParams(columnsArray, filterList, searchType)}
                      searchType={searchType}
                      setState={setState}
                      label={label}
                    />
                  )}
                  <TableFilterList {...props} serverSideFilterList={filterList} />
                  <div className={messageContainer}>
                    {showReportDownloadError && (
                      <Typography variant="h6" className={errorMessage}>
                        {t("datastore.reportDownloadError")}
                      </Typography>
                    )}
                  </div>
                </Fragment>
              ),
            }}
          />
        </MuiThemeProvider>
      )}
    </div>
  );
}

export default ObjectDataTable;
