import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { aviaryBlue, black, cobalt, lavenderBlue, midNightBlue, strongRed, powderBlue, silverGray } from "../theme";
import { CSSProperties } from "@material-ui/styles";
import { KeyValuePair } from "../tasks/type";
export const masterStyles = makeStyles(() =>
  createStyles({
    drawerPaperBody: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      backgroundColor: powderBlue,
    },
    drawerContent: {
      flex: "1 0 auto",
      backgroundColor: powderBlue,
      paddingLeft: 16,
      paddingRight: 16,
    },
    dashboardIconColor: {
      fill: cobalt,
    },
    drawerIconContainer: { padding: 12, display: "flex", justifyContent: "center" },
    draweListText: {
      color: cobalt,
      fontWeight: 600,
      fontFamily: "Inter",
      fontSize: 16,
      lineHeight: 24,
    },
    drawerListItem: {
      cursor: "pointer",
    },
    drawerLanguageBar: { flexShrink: 0, backgroundColor: powderBlue },
    drawerHeader: {
      paddingRight: 24,
      backgroundColor: powderBlue,
      paddingLeft: 24,
      paddingTop: 12,
      display: "flex",
      justifyContent: "space-between",
      height: 74,
    },
    languageBar: {
      padding: "16px 0px 16px 16px",
      boxShadow: "0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%)",
    },
    logoutPaper: { height: 200, width: 250, display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" },
    logoutPaperEmail: { display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" },
    logoutPaperBtn: { display: "flex", justifyContent: "center" },
    logoutHr: { borderColor: "#d2e0f7" },
    userIcon: { fontSize: 28, color: "#9b51e0" },
    userIconLarge: { width: 42, height: 42 },
    logoutMain: { width: "100%" },
    userInfoBlock: {
      padding: useTheme().spacing(1),
      paddingLeft: useTheme().spacing(2),
      paddingRight: useTheme().spacing(2),
      backgroundColor: "#f2f2f2",
      borderRadius: 20,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer",
      },
    },
    bold: {
      fontWeight: "bold",
    },
    textTransform: {
      textTransform: "none",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "21px",
      letterSpacing: "0em",
      textAlign: "left",
      color: "black",
      marginRight: useTheme().spacing(1),
    },
    listItemWrapper: { padding: 12, display: "flex", justifyContent: "center" },
  })
);

export const logoutStyles = makeStyles(theme =>
  createStyles({
    logout: {
      fontFamily: "inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "1%",
      textAlign: "left",
      textTransform: "capitalize",
      color: midNightBlue,
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      padding: "4px 12px",
      gap: 8,
      "&:hover": {
        backgroundColor: lavenderBlue,
      },
    },
    logoutPaper: {
      height: 150,
      width: 250,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    logoutPaperEmail: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      gap: 8,
      padding: "24px 24px 12px 24px",
    },
    logoutPaperBtn: { display: "flex", justifyContent: "flex-start", padding: "16px 12px" },
    logoutHr: { borderColor: silverGray },
    userIcon: { fontSize: 28 },
    userIconLarge: { width: "46px", height: "46px" },
    logoutMain: { width: "100%" },
    userInfoBlock: {
      gap: 8,
      padding: useTheme().spacing(1),
      paddingLeft: useTheme().spacing(2),
      paddingRight: "0px !important",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer",
      },
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    textTransform: {
      textTransform: "none",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "21px",
      letterSpacing: "0em",
      textAlign: "left",
      color: "black",
      marginRight: useTheme().spacing(2),
      marginLeft: useTheme().spacing(2),
    },
    userInfoText: {
      textTransform: "capitalize",
      fontFamily: "inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "24px",
      letterSpacing: "0em",
      textAlign: "center",
      color: midNightBlue,
      marginRight: useTheme().spacing(1),
    },
    popup: { marginTop: "50px" },
    userInfo: {
      display: "flex",
      flexDirection: "column",
    },
    userInfoTextStyle: {
      textTransform: "none",
      fontFamily: "inter",
      fontWeight: 600,
      color: midNightBlue,
      lineHeight: "24px",
      fontSize: "16px",
      letterSpacing: 0.16,
    },
    userEmailStyle: {
      textTransform: "none",
      fontFamily: "inter",
      fontWeight: 400,
      color: midNightBlue,
      lineHeight: "24px",
      fontSize: "16px",
      letterSpacing: 0.16,
    },
    gap: {
      marginLeft: "8px",
    },
  })
);

export const notificationStyles = (count: number, isItSmallMobile: boolean): KeyValuePair => {
  return {
    paper: { width: isItSmallMobile ? 288 : 400, borderRadius: "12px", height: 424 },
    titleBody: { display: "flex", justifyContent: "space-between", borderBottom: `1px solid ${aviaryBlue}`, padding: "16px 24px" },
    titleContent: { display: "flex", flexDirection: "column", alignItems: "center", color: cobalt, justifyContent: "center" },
    titleText: { fontSize: "20px", fontFamily: "Inter", lineHeight: "28px", color: midNightBlue, fontWeight: 600 },
    countText: { color: "inherit", fontSize: "2.2rem" },
    unreadText: { fontSize: "16px", fontFamily: "Inter", lineHeight: "24px", color: cobalt, fontWeight: 600 },
    notificationIcon: {
      width: 30,
      height: 30,
      marginRight: isItSmallMobile ? "8px" : "20px",
      padding: 7,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    noNotificationBody: { display: "flex", alignItems: "center", marginTop: "43px", flexDirection: "column" },
    noNotificationText: {
      fontSize: "16px",
      fontFamily: "Inter",
      lineHeight: "24px",
      color: black,
      padding: "12px",
      fontWeight: 400,
      marginTop: "12px",
    },
    listBody: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      border: `1px solid ${aviaryBlue}`,
      borderRadius: "4px",
      cursor: "pointer",
      padding: "16px",
    },
    listText: { fontSize: "16px", color: midNightBlue, fontFamily: "Inter", fontWeight: 400, lineHeight: "24px" },
    button: {
      backgroundColor: aviaryBlue,
      color: cobalt,
      textTransform: "none",
      fontSize: "12px",
      padding: "2px 8px",
      borderRadius: "4px",
      lineHeight: "20px",
      letterSpacing: "1%",
      fontFamily: "Inter",
      fontWeight: 600,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "none",
    },
    circle: { width: 10, marginRight: "16px" },
    countBody: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    notificationCount: {
      backgroundColor: strongRed,
      position: "absolute",
      top: "0%",
      left: "55%",
      borderRadius: "25px",
      padding: count > 9 ? "1px" : "1px 5px",
      color: "white",
      fontSize: "12px",
    },
    listButtonBody: { display: "flex", paddingTop: "4px" },
    popup: { marginTop: "50px", borderRadius: 12 },
    notificationBody: { padding: "16px 24px", display: "flex", flexDirection: "column", gap: 16 },
    notificationIconSize: {
      fontSize: isItSmallMobile ? 30 : 32,
    },
  };
};

export const headerStyle = {
  dashboardText: {
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "40px",
    letterSpacing: "0em",
    textAlign: "left",
    paddingLeft: 12,
  },
  dividerWrapper: { display: "flex", alignItems: "center", height: "46px" },
  divider: { height: "46px", alignSelf: "stretch" },
  menuStyle: { background: "transparent", border: "none", cursor: "pointer" } as CSSProperties,
};

export const headerPosition = (mobileView: boolean, tabletView: boolean, menuOpenSm: boolean) => {
  return {
    headerStack: {
      zIndex: (mobileView || tabletView) && menuOpenSm ? 0 : 999,
      backgroundColor: powderBlue,
      position: "sticky",
      top: 0,
    },
  };
};
export const logoutViewStyle = (mobileView: boolean): any => {
  return {
    accountIcon: { width: mobileView ? "30px" : "40px", height: mobileView ? "30px" : "40px" },
    expandIcon: { fill: cobalt },
  };
};

export const popOverStyles = makeStyles(theme => ({
  popoverRoot: {
    padding: 0,
    borderRadius: 12,
  },
  paper: {
    padding: 0,
    borderRadius: 12,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));
