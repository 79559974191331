import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, makeStyles } from "@material-ui/core";
import React, { Fragment } from "react";
import { black } from "../theme";
import { STRING } from "../../constants";

export interface ConfirmationDialogRawProps extends DialogProps {
  cancelLabel?: string;
  confirmLabel?: string;
  content: React.ReactNode;
  disabled?: boolean;
  displayDividers?: boolean;
  id: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  titleContent?: JSX.Element;
}

const useStyles = makeStyles({
  actions: {
    padding: 24,
  },
  contentText: {
    color: black,
    fontFamily: "Inter",
  },
  titleBody: {
    padding: "24px 24px 18px",
    fontsize: "20px",
    fontFamily: "Inter",
  },
});

function ConfirmationDialogRaw(props: ConfirmationDialogRawProps): JSX.Element {
  const { disabled, displayDividers, onConfirm, onCancel, confirmLabel, cancelLabel, title, titleContent, content, id, ...rest } = props;
  const { actions, contentText, titleBody } = useStyles();

  const renderContent = (
    <DialogContent dividers={displayDividers}>
      {typeof content === STRING ? (
        <DialogContentText className={contentText}>
          {(content as string)
            .split(/<\/?b>/) //split texts within <b></b> tags
            //even index text contains normal text and odd index text contains text which needs to be bolded
            .map((text, index) => (index % 2 === 0 ? <Fragment key={index}>{text}</Fragment> : <b key={index}>{text}</b>))}
        </DialogContentText>
      ) : (
        content
      )}
    </DialogContent>
  );

  return (
    <Dialog data-testid={id} disableBackdropClick maxWidth="xs" {...rest}>
      <DialogTitle className={titleBody} id="confirmation-dialog-title">
        {title || titleContent}
      </DialogTitle>
      {renderContent}
      <DialogActions className={actions}>
        {cancelLabel && (
          <Button className="cancel-button" data-testid="cancel" onClick={onCancel} variant="contained">
            {cancelLabel}
          </Button>
        )}
        {confirmLabel && (
          <Button
            className={`ml-1 ${disabled ? "disable" : "confirm"}-button`}
            data-testid="confirm"
            disabled={disabled}
            onClick={onConfirm}
            variant="contained"
          >
            {confirmLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialogRaw;
