import { createMuiTheme } from "@material-ui/core";
import { limeGreen, strongRed } from "../../../theme";

export const autoFillButtonTheme = (isAutofillTriggered: boolean | undefined) =>
  createMuiTheme({
    overrides: {
      MuiButton: {
        outlinedPrimary: {
          border: "2px solid #3f51b5",
          background: isAutofillTriggered ? "#EAF2FF" : "rgba(255, 255, 255, 0.95)",
          boxShadow: isAutofillTriggered
            ? "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)"
            : "none",
          "&:hover": {
            border: "2px solid #3f51b5",
            boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
          },
        },
        label: {
          fontSize: "0.85rem",
          fontWeight: 600,
        },
      },
    },
  });

export const outcomesViewStyles = (isCUT: boolean) => ({
  errorMsgStyle: {
    color: `var(--Error, ${strongRed})`,
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22.4px",
    letterSpacing: "0.32px",
    marginTop: "20px",
    paddingLeft: isCUT ? "24px" : "16px",
  },
  formErrorStyle: {
    paddingLeft: "16px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22.4px",
    letterSpacing: "0.32px",
    color: `var(--Error, ${strongRed})`,
  },
  successMsgStyle: {
    color: `var(--Success, ${limeGreen})`,
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22.4px",
    letterSpacing: "0.32px",
    paddingLeft: "16px",
    marginTop: "-16px",
  },
  autoFillButton: { marginLeft: "20px" },
  outcomeBtnContainer: { marginTop: "30px", paddingLeft: isCUT ? "24px" : "16px", marginBottom: "24px" },
});

export const outcomesBtnStyle = (outcomeBtn: string) => ({
  outcomeBtn: {
    marginLeft: outcomeBtn == "save" ? "0px" : "20px",
  },
});
