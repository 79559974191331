import React, { Fragment, useRef } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { InputAdornment, TextField } from "@material-ui/core";
import { DatePicker, MaterialUiPickersDate, MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import InputMask from "react-input-mask";
import { validateDate, getFormattedDate } from "../utils/toolbarHelper";
import { FilterParams, DateErrorFormat } from "../../../types/filterTypes";
import { cobalt } from "../../theme";
import { dateStyles } from "../styles/toolbarStyles";
import { EMPTY } from "../../../constants";

interface DateFilterProps extends WithTranslation {
  onFilterDataChange: (params: FilterParams) => void;
  handleSearch: () => void;
  dueDate?: string | null;
  dateFormatError?: DateErrorFormat | null;
  showError: boolean;
}

const DateFilter: React.FC<DateFilterProps> = ({ onFilterDataChange, handleSearch, dueDate, dateFormatError, showError, t }) => {
  const classes = dateStyles();
  const dateInputRef = useRef<any>(null);
  const textInputRef = useRef<HTMLInputElement | null>(null);

  const handleDateChange = (e: MaterialUiPickersDate) => {
    onFilterDataChange({ dueDate: e.format("DD/MM/YYYY"), dateFormatError: null });
  };

  const onChange = (e: React.FocusEvent<HTMLInputElement>) => {
    onFilterDataChange({ dueDate: e.target.value });
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value) {
      const v = validateDate(e.target.value, false);
      const { type, value } = v;
      if (type === "invalidDateFormat") {
        onFilterDataChange({ dateFormatError: v });
      } else {
        onFilterDataChange({ dueDate: value });
      }
    }
  };

  const onPressEnter = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };

  const onDateIconClick = () => {
    dateInputRef.current && dateInputRef.current.open();
  };

  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          value={dueDate && !dateFormatError ? getFormattedDate(dueDate, "/") : EMPTY}
          ref={dateInputRef}
          onChange={handleDateChange}
          disableOpenOnEnter
          animateYearScrolling={false}
          TextFieldComponent={() => <></>}
          id="datePicker"
        />
      </MuiPickersUtilsProvider>
      <InputMask mask={"99/99/9999"} value={dueDate ? dueDate : EMPTY} onChange={onChange} onBlur={onBlur}>
        {() => (
          <TextField
            id="date"
            inputRef={textInputRef}
            error={showError ? !!dateFormatError : false}
            helperText={
              showError && dateFormatError
                ? t(`dashboard.${dateFormatError.type}`, { value: dateFormatError.value, dateLimit: dateFormatError.dateLimit })
                : EMPTY
            }
            name="dateFilter"
            variant="outlined"
            value={dueDate ? dueDate : EMPTY}
            placeholder="DD/MM/YYYY"
            onKeyDown={onPressEnter}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start" onClick={onDateIconClick}>
                  <DateRangeIcon htmlColor={cobalt} />
                </InputAdornment>
              ),
              classes: {
                root: classes.root,
                notchedOutline: classes.notchedOutline,
                focused: classes.focused,
                input: classes.input,
              },
            }}
          />
        )}
      </InputMask>
    </Fragment>
  );
};

export default withTranslation()(DateFilter);
