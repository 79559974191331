import React from "react";
import { useTranslation } from "react-i18next";
import { createMuiTheme, Button, Grid, Tooltip, Typography, useMediaQuery } from "@material-ui/core";
import { contentStyles } from "./styles";
import { ArrowBack, LaunchRounded } from "@material-ui/icons";
import { updateFormState } from "../../rxjs/formBloc";
import history from "../../core/history";
import { EMPTY } from "../../constants";
import { CUT_FORM_PATH, FORM_DESIGNER_PATH } from "./constants";
import { checkForAccessTestRole } from "../tasks/GrapesForm/FormFields/helper";
import { getCurrentFormId, getRedirectBaseUrl, redirectToFormDesigner } from "./utils";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { CommonToolTipTheme, headerBaseButtonStyle } from "../theme";
import { toLocaleDateString } from "../../util/dateUtils";
import { ExpressionReplacer } from "@juakali/juakali-forms-react";

export interface HeaderProps {
  taskDetails: any;
  cutStore: any;
  assigneeComponent?: {
    canChangeAssignee: boolean;
    icon: JSX.Element;
    select: JSX.Element;
  };
  isReport?: boolean;
  formData?: any;
  headerTitle?: string;
  getCurrentForm: () => void;
  variables?: any;
  processVariables?: any;
}

export default function HeaderBase(props: any): JSX.Element {
  let {
    taskDetails,
    assigneeComponent,
    clearVariablesFromStores,
    cutStore,
    handleGoBackOnTeamView,
    headerTitle,
    processName,
    onGoBack,
    getCurrentForm,
    isReport,
    variables,
    processVariables,
  } = props;

  let { processDefinitionName, dueDate, name, title, assigneeName, formKey } = taskDetails || {};
  const taskName = name || title;
  const updatedTaskName = taskName ? ExpressionReplacer.replaceVariablesInExpression(taskName, variables, processVariables, true) : EMPTY;
  const formType = formKey && (formKey as string).split("-", 1)[0];
  const { t } = useTranslation(["translation", "flow"]);
  const theme = createMuiTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("xs"));
  const hasAccessTestRole = checkForAccessTestRole();
  const classes = headerBaseButtonStyle();
  let {
    headerIconContainer,
    redirectIcon,
    headerTitleContainer,
    typographyProcessname,
    label,
    backArrowIcon,
    textStyle,
    processNameStyle,
    value,
    dueDateWrapper,
    border,
    assigneeWrapper,
    wrapper,
    paddingTop,
    flex,
    reportHeaderContainer,
  } = contentStyles(isMobileView, formType);

  const handleButtonClick = () => {
    const isTeamTaskOrReport = assigneeComponent || isReport;
    const goBackHandlerForTeamTaskOrReport = (): void => {
      return isTeamTaskOrReport ? handleGoBackOnTeamView && handleGoBackOnTeamView() : history.push("/");
    };
    if (cutStore) {
      updateFormState({ errorMessage: EMPTY });
      clearVariablesFromStores && clearVariablesFromStores();
      onGoBack(() => goBackHandlerForTeamTaskOrReport());
    } else {
      goBackHandlerForTeamTaskOrReport();
    }
  };

  const handleToAccessFromInDesigner = (baseUrl: string): any => {
    const currentFrom = getCurrentForm && getCurrentForm();
    if (cutStore && currentFrom) {
      const { grapesForms } = cutStore;
      return window.open(`${baseUrl}/${FORM_DESIGNER_PATH}/${getCurrentFormId(grapesForms, currentFrom)}`);
    } else if (cutStore && !getCurrentForm()) {
      const {
        state: { id },
      } = cutStore;
      return window.open(`${baseUrl}/${CUT_FORM_PATH}/${id}`);
    } else {
      const formId = formKey && formKey.slice(13);
      const id = cutStore && cutStore && cutStore.combinedUserTaskConfiguration && cutStore.combinedUserTaskConfiguration.id;
      return redirectToFormDesigner(formType, baseUrl, id, formId);
    }
  };

  const renderLaunchRoundedIcon = (): JSX.Element => {
    return <LaunchRounded id="redirectIcon" onClick={() => handleToAccessFromInDesigner(getRedirectBaseUrl())} style={redirectIcon} />;
  };

  const renderRedirectIcon = () => {
    if (!cutStore) {
      return renderLaunchRoundedIcon();
    } else if (cutStore && getCurrentForm && !getCurrentForm()) {
      return renderLaunchRoundedIcon();
    }
  };
  return (
    <>
      <Grid style={headerIconContainer}>
        <MuiThemeProvider theme={CommonToolTipTheme}>
          <Tooltip title={t(`reports.backToList`) as string}>
            <Button data-testid="backToListBtn" onClick={handleButtonClick} style={textStyle} variant="contained" classes={{ root: classes.root }}>
              <ArrowBack style={backArrowIcon} /> {t(`reports.backToList`)}
            </Button>
          </Tooltip>
        </MuiThemeProvider>
      </Grid>
      <Grid style={headerTitleContainer}>
        <Typography variant="h5" component="h5">
          {updatedTaskName}
        </Typography>
        {hasAccessTestRole && !isReport && renderRedirectIcon()}
      </Grid>
      <Typography style={typographyProcessname}>{processName || processDefinitionName}</Typography>
      {!isReport && (
        <div style={wrapper} data-testid="assigneeComponent">
          <div style={dueDateWrapper}>
            <Typography style={label}>{t("tasks.dueDate")}:</Typography>
            <Typography style={{ ...value, ...paddingTop }}>{dueDate ? toLocaleDateString(dueDate) : "--"}</Typography>
          </div>
          <div style={border}></div>
          <div style={assigneeWrapper}>
            {assigneeComponent && assigneeComponent.canChangeAssignee ? (
              assigneeComponent.select
            ) : (
              <div>
                <Typography style={label}>{t(`dashboard.assignee`)}:</Typography>
                <div style={flex}>
                  <Typography style={value}>{assigneeName}</Typography>
                  {assigneeComponent && !assigneeComponent.canChangeAssignee && assigneeComponent.icon}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {headerTitle && (
        <Grid style={reportHeaderContainer}>
          <Typography style={processNameStyle}>{headerTitle}</Typography>
          {hasAccessTestRole && <>{(isReport || (getCurrentForm && getCurrentForm())) && renderLaunchRoundedIcon()}</>}
        </Grid>
      )}
    </>
  );
}
